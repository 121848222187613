<template>
    <div class="content" ref="content">
      <div class="content-top-bar" v-if="showCloseBtn">
        <DialogCloseBtn @click="$emit('showCloseBtnClicked')"></DialogCloseBtn>
      </div>
      <div class="content-item" v-if="tempMissionType=='joinTeam' || tempMissionType == 'register' || tempMissionType == 'login'">
        <div class="joinTeam-info" v-if="tempMissionType=='joinTeam'">
          <span class="joinTeam-info-tip">您受邀加入{{teamId}}团队的以下组织</span>
          <div class="orgs" >
            <el-tag class="truncate" v-for="(item,index) in organizations" :key="index" style="max-width:100%;margin-left: 10px;margin-top: 10px">{{item.name}}</el-tag>
          </div>
        </div>
        <div class="operation-wrap">
          <div class="normal-login operation-item"  v-if="!isAccountLogin"><!--微信登录 -->
            <el-tabs ref="switchBar" v-model="registerMethod" @tab-click="switchTab">
              <el-tab-pane class="wx-tab-pane" name="扫码注册" label="微信登录">
                <div v-show="wxloginFailed" class="wxlogin-failed-wrap" >
                  <span>微信二维码暂时无法加载</span>
                  <span>请使用其他登录方式或刷新页面</span>
                </div>
                <div v-show="!wxloginFailed" id="wechatcode">
                </div>
              </el-tab-pane>
              <el-tab-pane class="mb-tab-pane" name="手机注册" label="手机登录" > <!--手机登录 -->

                <el-form ref="phoneLoginRef" :rules="phoneLoginRules"  :model="registerData" label-width="0" class="text-inputs-container">
                  <el-form-item prop="phone">
                    <el-input v-model="registerData.phone" placeholder="请输入手机号" class="text-input" input-style="padding-left:40px">
                      <template #prefix>
                        <el-input v-model="registerData.country_code" input-style="padding:0;border:0;height:30px;text-align:center" style="width: 30px;">

                        </el-input>
                        <el-divider direction="vertical" style="margin-left: 0"></el-divider>
                      </template>
                    </el-input>

                  </el-form-item>
                  <el-form-item prop="phonecode">
                    <el-input v-model="registerData.phonecode" placeholder="请输入手机验证码" class="text-input" input-style="padding-right:90px" >
                      <template #suffix>
                        <div style="display: flex;align-items: center">
                          <el-divider direction="vertical"></el-divider>
                          <el-button @click="getPhoneCode('login_sms')" type="text" :disabled="codeButton.isDisabled">{{ codeButton.name }}</el-button>
                        </div>
                      </template>
                    </el-input>
                  </el-form-item>

<!--                  <el-form-item prop="invite_code"-->
<!--                                :style="{display: tempMissionType == 'register' ||-->
<!--                              tempMissionType == 'login' &&-->
<!--                              !isRegisteredPhone ? 'inline-block':'none'}"-->
<!--                  >-->
<!--                    <el-input placeholder="请输入邀请码" v-model="registerData.invite_code" class="text-input phonecode-input-wrap"/>-->
<!--                  </el-form-item>-->
                  <div class="submit-btn">
                    <el-button data-tag-id="user-phone-login" @click="saveUserInfo()" type="primary"
                                class="text-input"
                    >
                      {{tempMissionType == 'joinTeam' ? '立即加入':isRegisteredPhone ? '登录/注册':'注册'}}
                      </el-button>

                  </div>
                </el-form>
                <div class="user-policy">
                  <span>点击{{tempMissionType == 'joinTeam' ? '立即加入':isRegisteredPhone ? '登录/注册':'注册'}}代表你已阅读并同意</span><a target="_blank" href="#/servicePolicy">用户协议</a><span>和</span><a target="_blank" href="#/privacyPolicy">《隐私政策》</a>
                </div>
              </el-tab-pane>

            </el-tabs>
          </div>

          <div class="account-login operation-item" v-if="isAccountLogin">
            <p class="account-login-title alignMiddle">账号密码登录</p> <!--账号登录 -->
            <el-form ref="accountRef" :model="registerData" label-width="0" class="text-inputs-container login-content-form" :rules="emailLoginRules">
              <el-form-item prop="email">
                <el-input class="text-input" placeholder="输入邮箱/手机号" v-model="registerData.email"></el-input>
              </el-form-item>
              <el-form-item class="password-wrap" prop="password">
                <el-input class="text-input phonecode-input-wrap" placeholder="输入登录密码" v-model="registerData.password" show-password></el-input>
              </el-form-item>
            </el-form>
            <div class="submit-btn">
              <el-button data-tag-id="user-account-login" @click="accountLoginSubmit()" type="primary"
                          class="text-input">
                登 录
              </el-button>
            </div>


          </div>
        </div>



        <div class="content-item-bottom" v-if="!config.noModel">

            <p class="method-switch-btn alignMiddle use-account" v-if="!isAccountLogin" @click="switchTab({paneName:'account'})">
              账号密码登录
            </p>
            <p v-else class="method-switch-btn alignMiddle use-wx" @click="()=>{
            isAccountLogin = false;
            $nextTick(()=>{
              switchTab({paneName:'扫码注册'});
            })
          }">
              微信扫码登录
            </p>
        </div>
        <slot name="tip">
        </slot>
      </div>

      <div class="content-item completeUserInfo-container" v-if="tempMissionType == 'completeUserInfo'">
        <span class="cui-title">完善账户信息</span>
        <el-form :rules="completeInfoRule" ref="userInfor" :model="registerData" label-width="0">
          <el-form-item prop="email" v-if="registerData.email">
            <el-input placeholder="邮箱"  v-model="registerData.email" disabled></el-input>
          </el-form-item>
          <el-form-item prop="phone">
            <el-input placeholder="手机号" v-model="registerData.phone"></el-input>
          </el-form-item>
          <el-form-item prop="phonecode">
            <el-input placeholder="验证码" v-model="registerData.phonecode" input-style="padding-right:90px">
              <template #suffix>
                <div style="display: flex;align-items: center">
                  <el-divider direction="vertical"></el-divider>
                  <el-button @click="getPhoneCode('signup')" type="text" :disabled="codeButton.isDisabled">{{ codeButton.name }}</el-button>
                </div>
              </template>
            </el-input>
          </el-form-item>
<!--          <el-form-item v-if="validCodeControl" prop="invite_code">-->
<!--            <el-input placeholder="邀请码" v-if="registerData.invite_code"   v-model="registerData.invite_code"  disabled></el-input>-->
<!--            <el-input placeholder="邀请码" v-else v-model="registerData.invite_code"></el-input>-->
<!--          </el-form-item>-->
          <el-form-item>
            <el-button  @click="finishUserInfo" data-tag-id="complete-user-info" type="primary" class="text-input">
              完成
            </el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
</template>

<script>
import {colorLog, getCookie, MetaMessage, uuidGen} from "../../util";
import {
  checkPhoneCode,
  getOrganizationsInviteInfo,
  getPhoneCode, getQRCode,
  getValid, loginByAccount, loginByPhone,
  loginUranus,
  requestLogin
} from "../../api/api";
import config from "../../config";
import {JSEncrypt} from "jsencrypt";
import {globalTagClick} from "../../assets/js/tag/tag";
import DialogCloseBtn from "../universalUI/DialogCloseBtn";
import autoLogin from "../../assets/js/autoLogin";

export default {
  name: "AuthorizeUserComponent",
  components: {DialogCloseBtn},
  props:{
    'missionType':{
      default:'joinTeam' //组件的用途 joinTeam,login
    },
    showCloseBtn:{
      default:false
    },
    inviteCode: {
      type: String,
      required: false,
      default:config.registerInviteCode,
    },
    source: {
      type: String,
      requires: false,
      default:''
    }
  },
  data(){
    let checkPhoneNum = (rule, value, callback) => {
      if (!value) {
        callback(new Error('手机号不能为空'))
      } else if (isNaN(value)) {
        callback(new Error('请输入0-9的数字'))
      } else if (value.length < 8 || value.length > 14) {
        callback(new Error('请输入8-14位有效手机号码'))
      } else {
        callback()
      }
    }

    let checkPhoneCode = (rule, value, callback) => {
      if (!value) {
        callback(new Error('验证码不能为空'))
      } else if (isNaN(value)) {
        callback(new Error('请输入0-9的数字'))
      } else if (value.length !== 4) {
        callback(new Error('请输入4为有效数字验证码'))
      } else {
        callback()
      }
    }

    let invitedCodeValidator = (rule, value, callback) => {
      if (!value) {
        callback(new Error('邀请码不能为空'))
      } else {
        callback();
        // this.checkInvitedCode(callback)
      }
    };

    /**
     * 邮箱和手机号码登录
     * @param rule
     * @param value
     * @param callback
     */
    let checkLoginByEmailOrPhone = (rule, value, callback) => {
      let reg = /(^[\w.\-]+@(?:[a-z0-9]+(?:-[a-z0-9]+)*\.)+[a-z]{2,3}$)|(^1[3|4|5|7|8|9]\d{9}$)/i
      if (!value) {
        callback(new Error('邮箱或者手机号不能为空'))
      } else if (!reg.test(value)) {
        callback(new Error('请检查账号格式'))
      } else {
        callback()
      }
    }

    return{
      config:config,
      tempMissionType:this.missionType,
      registerData: {
        nickname:'',
        country_code: '86',
        phonecode:'',
        method:'phone',
        email: '',
        invite_code: config.registerInviteCode
      },
      codeButton: {
        isDisabled: false,
        name: '获取验证码',
        time: 60
      },
      timer:null,
      metaMessage: new MetaMessage(),
      teamId:'xxx',
      registerMethod:'扫码注册',
      wxloginFailed:false,
      organizations:[],
      loginMethod:'login',
      isRegisteredPhone:true,
      isAccountLogin:config.noModel ? true : false,//'账号密码登录' 按钮维护的变量

      //输入框信息校验
      phoneLoginRules: {
        phone: [
          { required: true, validator: (rule, value, callback) => {
              if (!value) {
                callback(new Error('手机号不能为空'))
              } else if (isNaN(value)) {
                callback(new Error('请输入0-9的数字'))
              } else if (value.length < 8 || value.length > 14) {
                callback(new Error('请输入8-14位有效手机号码'))
              } else {
                callback()
              }
            }, trigger: 'blur' }
        ],
        phonecode: [
          { required: true, validator: checkPhoneCode, trigger: 'blur' }
        ],
        invite_code: [
          { required: true, validator: invitedCodeValidator, trigger: ['blur', 'change'] }
        ]
      },
      emailLoginRules: {
        email: [
          { required: true, validator: checkLoginByEmailOrPhone, trigger: 'blur' }
        ],
        password: [
          { required: true, message: '密码不能为空', trigger: 'blur' }
        ]
      },
      completeInfoRule: {
        // nickname:[{ required: true, validator: (rule, value, callback) => {
        //   console.log('nickname',value)
        //     if (!value || !/\S/g.test(value)) {
        //       callback(new Error('用户名不能为空'));
        //     }else{
        //       callback();
        //     }
        //   }, trigger: 'blur' }],
        phone: [
          { required: true, validator: checkPhoneNum, trigger: 'blur' }
        ],
        phonecode: [
          { required: true, validator: checkPhoneCode, trigger: 'blur' }
        ],
        invite_code: [
          { required: true, validator: invitedCodeValidator, trigger: ['blur', 'change'] }
        ],
        // email: [
        //   { type: 'email', trigger: 'blur' },
        //   { validator: checkLoginByEmailOrPhone, trigger: 'blur' }
        // ],
        completed:false,

        //微信二维码
        meta_scene_token:'',
        qr_code_url:'',
      },
    }
  },
  created() {
    if (this.inviteCode) {
      this.registerData.invite_code = this.inviteCode;
    }
  },
  mounted() {
    console.log('组件使用的模式 : ',this.missionType);
    localStorage.removeItem("loginByAccount");
    if(this.missionType !== 'completeUserInfo'){
      this.showLoginQRCode();
    }
    if(this.missionType == 'joinTeam'){
      let inviteKey=this.$route.query.inviteKey;
      this.getInviteOrganizationInfo(inviteKey)
    }else if(this.missionType == 'completeUserInfo'){
      this.registerData.method = 'clm';
      let now = Date.now();
      let getUserInfo = ()=>{
        let userInfo = JSON.parse(sessionStorage.getItem("userInfo"));
        console.log('获取userInfo',userInfo);
        Object.assign(this.registerData,userInfo);
        try{
          this.registerData.nickname=userInfo.userName;
          return true;
        }catch(e){
          console.log('设置用户名失败');
          return false;
        }
      }

      let timer = setInterval(()=>{
        function clear(){
          clearInterval(timer);
          timer = null;
        }
        if(getUserInfo() || Date.now() - now >10000){
          clear();
        }
      },1500);

    }
  },
  unmounted() {
    this.completed = true;
  },
  methods:{
    getInviteOrganizationInfo(inviteKey){
      getOrganizationsInviteInfo(inviteKey).then(res=>{
        this.organizations=res.data.data.organizations;
        this.teamId = res.data.data.teamName;

      })
    },
    finishUserInfo(){
      let pro = this.$refs.userInfor.validate();
      pro.then(()=>{
        let params = {
          type: 'signup',
          content: this.registerData.phonecode
        };
        console.log('补全信息');
        checkPhoneCode(params).then(res => {
          if (res.data.code === 0) {
            if(this.registerData.inviteCode===config.guestInviteCode){
              this.registerData.method="clm"
            }
            requestLogin(null, this.registerData, (res) => {
              if (res.user && res.user.code === 0) {
                console.log('补全信息并注册成功');
                this.success('signupSuccess');
              }else{
                this.metaMessage.error(res.user.msg)
              }
            })
          }else{
            this.metaMessage.error("验证失败");
          }
        });
      })

    },
    async accountLoginSubmit(){
      let pro = this.$refs['accountRef'].validate();
      console.log('账号登录');
      pro.then(()=>{
        // 登录密码需要加密传输
        let publicKey = 'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCX4kRYjU6XclWT4mwxgQ55JerRar8CPEHp4uZRITqZuk8p0z5UTL02muequw2dgvgNlJkE8C7eyXf1S8TH+MvbVkHnCoKKigAPb08PtqcvVIqnZn+kNDpiQZapkpHrEXeR+rWfj3etTwMHYbV+zTaKOdxa7fm3oO4CWbgmfkSC8wIDAQAB'
        let encryptor = new JSEncrypt();
        encryptor.setPublicKey(publicKey)
        let params = {
          login_by: 'account',
          account: this.registerData.email,
          pwd: encryptor.encrypt(this.registerData.password)
        }
        loginByAccount(params).then(res => {
          if (res.data.code === 0) {
            // if (this.$getCookie('register')) {
            //   this.tempMissionType = 'completeUserInfo';
            //   console.log('账号登录->补全信息');
            //   this.getValidFunc();
            // } else {
              console.log('账号登录成功');
              //标记为"使用账号登录"
              localStorage.setItem("loginByAccount",1);
              this.success('loginSuccess');
            // }
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        }).catch(err => {
          colorLog.error(err)
          this.$message({
            type: 'error',
            message: err
          })
        })
      })

    },
    async saveUserInfo(){
      if (this.registerData.phone === '' || this.registerData.phonecode === '') {
        this.$refs['phoneLoginRef'].validateField('phone');
        this.$refs['phoneLoginRef'].validateField('phonecode');
      } else {
        if(this.isRegisteredPhone){
          console.log('手机登录');
          this.loginByPhone()
        }else {
          console.log('手机注册');
          let params = {
            type: 'signup',
            content: this.registerData.phonecode
          };
          checkPhoneCode(params).then(res => {
            if (res.data.code === 0) {
              requestLogin(null, this.registerData, (res) => {
                if (res.user && res.user.code === 0) {
                  console.log('手机注册成功');
                  this.success('signupSuccess');
                } else {
                  this.metaMessage.error(res.user.msg)
                }
              })
            }
          });
        }
      }

    },

    success(eventName){
      this.$emit(eventName);
      autoLogin(getCookie('sid'));
    },

    isEmpty(str){
      return str==null||str=='';
    },
    getPhoneCode(loginType) {
      this.codeButton.isDisabled = true;
      this.isRegisteredPhone=true;
      let type = {
        type: loginType
      }
      let params = {
        phone: this.registerData.country_code === '' || this.registerData.country_code === undefined
            ? '86-' + this.registerData.phone : this.registerData.country_code + '-' + this.registerData.phone
      }
      getPhoneCode(type, params).then(res => {
        if (res.data.code === 0) {
          this.timer = window.setInterval(() => {
            this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
            --this.codeButton.time;
            if (this.codeButton.time < 0) {
              this.resetCodeButton();
            }
          }, 1000);
        } else if (res.data.code === 5) {
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
            // eslint-disable-next-line no-unused-vars
            callback: (_) => {
              this.timer = window.setInterval(() => {
                this.codeButton.name = '(' + this.codeButton.time + '秒)后重发';
                --this.codeButton.time;
                if (this.codeButton.time < 0) {
                  this.resetCodeButton();
                }
              }, 1000);
            },
          });
        }
        else if (res.data.code === 2701 && res.data.msg === '手机号不存在,请先注册!') {
          this.getPhoneCode("signup");
          console.log('将isRegisteredPhone=false后置');
          this.isRegisteredPhone = false;
        }
        else{
          this.$alert(res.data.msg, '提示', {
            confirmButtonText: '确定',
            callback:(_)=>{
              this.resetCodeButton();
            }});
        }
      })
    },
    resetCodeButton: function () {
      this.codeButton = {
        isDisabled: false,
        name: '获取验证码',
        time: 60
      };

      window.clearInterval(this.timer);
    },
    loginByPhone(){
      let params = {
        login_by: 'sms',
        phone: this.registerData.country_code === '' || this.registerData.country_code === undefined
            ? '86-' + this.registerData.phone : this.registerData.country_code + '-' + this.registerData.phone,
        sms_code: parseInt(this.registerData.phonecode)
      };
      loginByPhone(params).then(res => {
        if (res.data.code === 0) {
          this.success('loginSuccess')
        } else {
          this.$message({
            type: 'error',
            message: res.data.msg
          });
        }
      }).catch(err => {
        console.error(err);
        this.$message({
          type: 'error',
          message: '登录失败'
        });
      });
    },
    emitExternal(eventName){
      this.completed = true;
      this.$emit(eventName);
    },
    showLoginQRCode: async function () {
      if (config.noModel) return;
        let token = this.meta_scene_token;
        let url = this.qr_code_url;
        if(token){
          appendImg(token,url);
          return;
        }

      let res = await getQRCode();
      if(res.status !== 200 && res.data.code !== 0) return;
      token = res.data.data.meta_scene_token;
      url = res.data.data.qr_code_url;

      this.meta_scene_token = token;
      this.qr_code_url = url;
      appendImg(token,url);

      //插入图片的方法
      let that = this;
      function appendImg(uuid,url) {
        const wechatContainer = document.getElementById("wechatcode");
        const qrImg = document.createElement("IMG");
        qrImg.style = "width: 100%; heigh: 100%";
        qrImg.src = url;
        qrImg.onerror = () => {
          that.wxloginFailed = true;
        };
        qrImg.onload = () => {
          that.loginByQRCode(uuid, url);
        };
        wechatContainer.innerHTML = '';
        wechatContainer.appendChild(qrImg);
      }
    },
    switchTab(pane){

      switch (pane.paneName){
        case "扫码注册":
          this.showLoginQRCode();
          this.registerMethod = '扫码注册';
          break;
        case "account":
          this.isAccountLogin = true;
          break;
        default:
          break;
      }
    },
    loginByQRCode: function (uuid) {
      let login = function () {
        let params = {
          login_by: 'metago_scan',
          meta_token: uuid,
        };

        return loginUranus(params);
      };

      /**
       * fun 要求是promise
       */
      let repeat = (fun, interval) => {

        if(this.completed ){
          return;
        }
        if(this.registerMethod !== '扫码注册' || this.isAccountLogin){
          const unwatch = this.$watch(() => {
            return this.registerMethod !== '扫码注册' || this.isAccountLogin;
          }, (notScanQRCodeUI) => {
            if(!notScanQRCodeUI){
              unwatch();
              repeat(fun, interval);
            }
          })
          return;
        }

        setTimeout(() => {
          fun().then(res => {
            if (res.data.code === 9) {
              repeat(fun, interval);
              return;
            } else if (res.data.code === 8 || res.data.code === 10) {
              return Promise.reject(res.msg);
            } else if (res.data.code === 0) {
              if (this.$getCookie('register')) {
                console.log('微信登录->补全信息');
                this.tempMissionType = 'completeUserInfo';
                this.registerData.method = '';
                this.getValidFunc();
              } else {
                globalTagClick('user-wx-QRCode-login');
                console.log('微信登录成功');
                this.success("loginSuccess")
              }
              return;
            }
            return Promise.reject(res.data.msg);
          }).catch(err => {
            if (typeof instance === "string") {
              this.$message({
                type: 'error',
                message: err
              });
            } else {
              /**
               * 位置错误 不处理
               */
              //layer.msg("登录失败， 请刷新重试");
            }
          });
        }, interval);
      };

      repeat(login, 1000);
    },
    getValidFunc: function () {
      let params = {
        type: 'invite_code',
        content: 'SYS-OPN00000000'
      }

      getValid(params).then(res => {
        if (res.data.code === 0) {
          this.validCodeControl = true
        } else {
          this.validCodeControl = false
        }
      })
    },
    jump(url){
      if(url.startsWith("http")){
        window.location.href= url;
      }
      this.$router.push(url);
    }
  }
}
</script>
<style scoped>
.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.content{
  --login-compoent-height:38em;
  --login-compoent-width:32em;
  --login-compoent-top-bar-height:3em;
  font-size: 10px;

  background-color: white;
  box-shadow: rgb(60 64 67 / 15%) 0 1px 5px 2px;
  z-index: 1;
  width: var(--login-compoent-width);
  min-height: var(--login-compoent-height);
  border-radius: 5px;


  --header-tab-button-height:4em;
  --header-tab-button-font-size:1.8;
  --operation-wrap-height:31em;

  --text-inputs-container-margin-top:3em;

  box-shadow: 0 0 3px 2px var(--gray-2);
}
.content-top-bar{
  height:var(--login-compoent-top-bar-height);
  box-sizing: border-box;
  display: flex;
  padding: .5em;
  position: relative;
}

.content-item{
  padding:0 2em 0;
  box-sizing: border-box;
}
.content-item-bottom{
  height: calc(var(--login-compoent-height) - var(--operation-wrap-height) - var(--login-compoent-top-bar-height));
}
.operation-wrap{
  min-height: var(--operation-wrap-height);
  box-sizing: border-box;
  border-bottom: 1px solid var(--gray-1);
}
.normal-login{
}
.method-switch-btn{
  text-align: center;
  cursor: pointer;
  margin: 0;
  height: 100%;
  font-size: 1.6em;
  color:var(--font-gray);
}
.use-account{
}

.alignMiddle::before {
  content: '';
  width: 0;
  height: 100%;
  vertical-align: middle;
  display: inline-block;
}

.user-policy{
  font-size: 1.2em;
  text-align: center;
  margin-top: calc(2.5em / 1.2);
}
.user-policy a{
  color: var(--lightBlue);
  text-decoration: none;
}

.content >>> .el-tabs .el-tabs__item{
  width: 50%;
  font-size: calc(var(--header-tab-button-font-size) * 1em);
  /*(4 / 1.6)*/
  height: calc(var(--header-tab-button-height)  / var(--header-tab-button-font-size));
}
.content >>> .el-tabs__nav.is-top{
  width: 100%;
}

.content >>> .el-tabs__nav-wrap::after{
  height: 0;
}
.content >>> .el-tabs__header{
  margin: 0;
}
.content >>> .el-tabs{
  height: 100%;
}
.content >>> .el-tabs__content{
}
.content >>> .el-tab-pane{
  position: relative;
}
.wx-tab-pane{
  min-height: calc(var(--operation-wrap-height) - var(--header-tab-button-height));

}
.mb-tab-pane{
  margin-top: var(--text-inputs-container-margin-top);
  min-height: calc(var(--operation-wrap-height) - var(--header-tab-button-height) - var(--text-inputs-container-margin-top));
}
.content >>> .el-button--primary{
  width: 10em;
}
.content >>> .el-form-item{
  margin-bottom: 2.2em;
}
.content >>> .el-input__inner{
  height: calc(4em / 1.4);
}

.wxlogin-failed-wrap{
  height: 22em;
  width: 22em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin: auto;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
}
.wxlogin-failed-wrap > span{
  font-size: 1.4em;
}

#wechatcode{
  margin: auto;
  position: absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  height: 22em;
  width: 22em;

  border: 1px solid #ddd;
}

.orgs{
  text-align: center;
}
.text-input{

}
.text-inputs-container{
  margin-top: var(--text-inputs-container-margin-top);
}
.el-form-item >>> .el-form-item__label{
  white-space: pre;
}
.account-login-title{
  font-size: calc(var(--header-tab-button-font-size) * 1em);
  height: calc(var(--header-tab-button-height)  / var(--header-tab-button-font-size));

  /*(4 / 1.6)*/
  color:var(--lightBlue);
  margin: 0;
  text-align: center;
}
.joinTeam-info{
  color:var(--lightBlue);
}
.cui-title{
  font-weight: bold;font-size: 18px;display: block;text-align: center;padding: 20px 0;
}

.completeUserInfo-container{
  padding-bottom: 2em;
}

.completeUserInfo-container .el-form{
  height: calc(28em + 6px);
  position:relative;
}

.completeUserInfo-container .el-form .el-form-item:last-child{
  position:absolute;
  inset: auto 0 0;
  margin: 0 auto;
}
.operation-item{
  min-height: var(--operation-wrap-height);
}
.submit-btn{
}
.joinTeam-info{

  padding-top: 5px;
}
.joinTeam-info .joinTeam-info-tip{
  font-weight: bold;font-size: 18px;display: block;text-align: center;
}
</style>
