import config,{DEPLOY_TYPE} from '../../config'
export default function autoLogin (sid){
    if([DEPLOY_TYPE.TEST,DEPLOY_TYPE.TESTCLM,DEPLOY_TYPE.LOCALCLM,DEPLOY_TYPE.TESTDEV].includes(config.deployType)){
        return;
    }

    function addIFrame(src = ''){
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none';
        iframe.setAttribute('src',src + sid);
        document.body.appendChild(iframe);
    }

    addIFrame('https://metapro.cn/autologin?sid=');
    addIFrame('https://metaso.cn/static/login/autologin.html?sid=');
    addIFrame('https://xiezuocat.com/static/login/autologin.html?sid=');
}
